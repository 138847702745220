import clsx from "clsx";
import { getClient } from "lib/sanity/server";
import _omit from "lodash/omit";
import { useNextSanityImage } from "next-sanity-image";
import Image, { ImageProps } from "next/image";
import React from "react";

import { SanityImage } from "../types";

type ImageRendererProps = Omit<ImageProps, "src"> & {
  value: SanityImage;
  className?: string;
  imageClassName?: string;
  layout?: ImageProps["layout"];
  withContainer?: boolean;
};

const ImageRenderer: React.FC<ImageRendererProps> = ({
  value,
  className,
  imageClassName = "rounded-md",
  layout,
  withContainer = true,
  ...rest
}) => {
  // Remove props that get passed from the portable text
  const passProps = _omit(rest, "isInline", "renderNode");
  const imageProps = useNextSanityImage(getClient(), value);

  if (!value?.asset) {
    return null;
  }

  const imageContent = (
    <Image
      {...imageProps}
      height={layout !== "fill" ? imageProps.height : undefined}
      width={layout !== "fill" ? imageProps.width : undefined}
      alt={value.alt}
      className={clsx("max-w-full crispImage", imageClassName)}
      layout={layout}
      {...passProps}
    />
  );

  if (!withContainer) {
    return imageContent;
  }

  return <div className={clsx("relative", className)}>{imageContent}</div>;
};

export default ImageRenderer;
