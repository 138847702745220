import React from "react";
import { EmailShareButton, FacebookShareButton, LinkedinShareButton, TwitterShareButton } from "react-share";

import { faFacebookF, faLinkedinIn, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MailIcon } from "@heroicons/react/outline";

export enum ShareOptions {
  Email = "email",
  LinkedIn = "linkedIn",
  Facebook = "facebook",
  Twitter = "twitter",
}

const OptionMapping = {
  [ShareOptions.Email]: { Button: EmailShareButton, Icon: props => <MailIcon {...props} /> },
  [ShareOptions.LinkedIn]: {
    Button: LinkedinShareButton,
    Icon: props => <FontAwesomeIcon icon={faLinkedinIn} {...props} />,
  },
  [ShareOptions.Facebook]: {
    Button: FacebookShareButton,
    Icon: props => <FontAwesomeIcon icon={faFacebookF} {...props} />,
  },
  [ShareOptions.Twitter]: {
    Button: TwitterShareButton,
    Icon: props => <FontAwesomeIcon icon={faTwitter} {...props} />,
  },
};

type OptionRendererProps = {
  type: ShareOptions;
  url: string;
  className?: string;
  iconFillColor?: string;
  size?: number | string;
};
const OptionRenderer = ({ type, url }: OptionRendererProps) => {
  if (!OptionMapping[type]) {
    return null;
  }

  const { Button, Icon } = OptionMapping[type];
  return (
    <Button url={url} className="!bg-gray-200 !rounded-full !p-1 group h-10 w-10 flex items-center justify-center">
      <Icon className="h-6 w-6 text-gray-500 group-hover:text-primary transition-colors text-lg" />
    </Button>
  );
};

type ShareIconsProps = {
  icons: ShareOptions[];
  url: string;
};
const ShareIcons = ({ icons, url, ...rest }: ShareIconsProps) => (
  <div className="flex gap-2 items-center">
    {icons.map(type => (
      <OptionRenderer {...rest} key={type} type={type} url={url} />
    ))}
  </div>
);

export default ShareIcons;
