import { ButtonColorSchemes, ButtonVariants } from "components/button";

export enum NavbarVariants {
  Default = "default",
  LogoOnly = "logoOnly",
  Custom = "custom",
}

export enum NavItemTypes {
  Link = "link",
  Button = "button",
}

export const loginUrl = "https://enerflo.io/home";

export const navigation = {
  features: {
    name: "Features",
    href: "/features",
    type: NavItemTypes.Link,
    items: [
      { name: "Overview", href: "/features", type: NavItemTypes.Link },
      { name: "Sales Process", href: "/features/sales-process", type: NavItemTypes.Link },
      { name: "Project Management", href: "/features/project-management", type: NavItemTypes.Link },
      { name: "Customer Engagement", href: "/features/customer-engagement", type: NavItemTypes.Link },
      { name: "Docflo", href: "/features/docflo", type: NavItemTypes.Link },
      { name: "Lendflo", href: "/features/lendflo", type: NavItemTypes.Link },
    ],
  },
  integrations: {
    name: "Integrations",
    href: "/integrations",
    type: NavItemTypes.Link,
    items: [
      { name: "Overview", href: "/integrations", type: NavItemTypes.Link },
      { name: "Sales Qualification", href: "/integrations/sales-qualification", type: NavItemTypes.Link },
      { name: "Solar Design", href: "/integrations/solar-design", type: NavItemTypes.Link },
      { name: "Solar Finance", href: "/integrations/solar-finance", type: NavItemTypes.Link },
      { name: "API Documentation", href: "https://docs.enerflo.io", type: NavItemTypes.Link },
    ],
  },
  about: {
    name: "About",
    href: "/about",
    type: NavItemTypes.Link,
    items: [
      { name: "Company", href: "/about", type: NavItemTypes.Link },
      { name: "Contact Us", href: "/about/contact", type: NavItemTypes.Link },
      { name: "Partner with Enerflo", href: "/about/partner", type: NavItemTypes.Link },
      { name: "Resources", href: "/resources", type: NavItemTypes.Link },
      { name: "Discover Enerflo", href: "/demo", type: NavItemTypes.Link, footerOnly: true },
    ],
  },
  support: {
    name: "Support",
    href: "/support",
    type: NavItemTypes.Link,
  },
  discoverEnerflo: {
    name: "Discover Enerflo",
    href: "/demo",
    type: NavItemTypes.Button,
    variant: ButtonVariants.Solid,
  },
  login: {
    name: "Login",
    href: loginUrl,
    type: NavItemTypes.Button,
    variant: ButtonVariants.Outline,
    analyticEvent: {
      event: "Utility Button",
      category: "Buttons",
      label: "Login",
    },
  },
};

export const headerNavItems = [
  navigation.features,
  navigation.integrations,
  navigation.about,
  navigation.support,
  navigation.discoverEnerflo,
  navigation.login,
];

export type NavigationItem = {
  name: string;
  href: string;
  // When true, represents the default child nav item for dropdown navs
  isDefault?: boolean;
  items?: NavigationItem[];
  // When true, will only render the nav item in the footer and not be present in the header
  footerOnly?: boolean;
  type: NavItemTypes;
  // When item type is a button, the variant of the button
  variant?: ButtonVariants;
  // When item type is a button, the color scheme to use for the button
  color?: ButtonColorSchemes;
  // When present, will trigger an analytic event upon click
  analyticEvent?: {
    event: string;
    category: string;
    label: string;
  };
};
