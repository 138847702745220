import VideoPlayer from "components/video-player";

import { SanityVideo } from "../types";

const VideoRenderer: React.FC<{ value: SanityVideo; onPlay?: any }> = ({ value, onPlay }) => {
  const { url, options } = value || {};

  if (!url) {
    return null;
  }

  const { width, height } = options || {};

  return <VideoPlayer url={url} onPlay={onPlay} width={width} height={height} />;
};

export default VideoRenderer;
