import clsx from "clsx";
import Link from "next/link";
import { AnchorHTMLAttributes, ReactNode, SyntheticEvent } from "react";

export enum ButtonVariants {
  Link = "link",
  Solid = "solid",
  Outline = "outline",
}

export enum ButtonColorSchemes {
  Primary = "primary",
  Navy = "navy",
  White = "white",
  Dark = "dark",
  Secondary = "secondary",
}

export const buttonColorMapping = {
  [ButtonColorSchemes.Dark]: "btn-dark",
  [ButtonColorSchemes.Navy]: "btn-navy",
  [ButtonColorSchemes.White]: "btn-white",
  [ButtonColorSchemes.Primary]: "btn-primary",
  [ButtonColorSchemes.Secondary]: "btn-secondary",
};

type ButtonProps = {
  children: ReactNode;
  className?: string;
  href?: string;
  variant?: ButtonVariants;
  color?: ButtonColorSchemes;
  onClick?: (e: SyntheticEvent) => void;
  rel?: AnchorHTMLAttributes<HTMLAnchorElement>["rel"];
  target?: AnchorHTMLAttributes<HTMLAnchorElement>["target"];
};

const Button = ({
  children,
  className,
  href,
  variant,
  color = ButtonColorSchemes.Primary,
  onClick,
  target,
  rel,
}: ButtonProps) => {
  const classes = clsx(
    className,
    buttonColorMapping[color],
    "btn",
    variant === ButtonVariants.Outline && "btn-outline",
    variant === ButtonVariants.Link && "btn-link"
  );

  if (href || target || rel) {
    if (href && href.startsWith("/")) {
      return (
        <Link href={href}>
          <a className={classes} onClick={onClick} target={target} rel={rel}>
            {children}
          </a>
        </Link>
      );
    }

    return (
      <a href={href} className={classes} onClick={onClick} target={target} rel={rel}>
        {children}
      </a>
    );
  }

  return (
    <button type="button" className={classes} onClick={onClick}>
      {children}
    </button>
  );
};

export default Button;
