import { PhotographIcon } from "@heroicons/react/outline";

const ImagePlaceholder = () => (
  <div
    className="bg-gray-50 text-gray-300 text-2xl flex shrink-0 items-center justify-center w-full border-gray-200"
    data-test="image-placeholder"
  >
    <PhotographIcon className="block h-6 w-6" />
  </div>
);

export default ImagePlaceholder;
