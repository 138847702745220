import Heading, { HeadingProps } from "components/heading";
import React from "react";

import { PortableTextBlockComponent } from "@portabletext/react";

const BlockRenderer: PortableTextBlockComponent = ({ children, value }) => {
  const style = value.style || "normal";

  // render h1-h6 tags
  if (/^h\d/.test(style)) {
    return <Heading tag={style as HeadingProps["tag"]}>{children}</Heading>;
  }

  if (style === "blockquote") {
    return <blockquote className="border-l-2 border-gray-300 border-opacity-80 pl-2 italic">{children}</blockquote>;
  }

  return <p>{children}</p>;
};

export default BlockRenderer;
