const createClient = require("@sanity/client");
const sanityConfig = require("./config");

const isServer = typeof window === "undefined";

const serverConfig = {
  ...sanityConfig,
  useCdn: false,
  token: process.env.SANITY_API_READ_TOKEN,
};

const getClient = preview => createClient(preview || isServer ? serverConfig : sanityConfig);

module.exports = { getClient };
