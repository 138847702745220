import clsx from "clsx";
import React from "react";
import ReactPlayer, { ReactPlayerProps } from "react-player";

import styles from "./index.module.css";

const parseSize = (size: number | string | undefined) => {
  if (typeof size === "number") {
    return `${size}px`;
  }

  return size;
};

interface Props extends ReactPlayerProps {
  className?: string;
  url: string;
}

const VideoPlayer: React.FC<Props> = ({ className, url, width, height, ...props }) => {
  return (
    <div className={clsx(styles.videoWrapper, className)}>
      <ReactPlayer
        controls={true}
        playsinline={true}
        config={{
          youtube: {},
          vimeo: {
            playerOptions: {
              byline: false,
            },
          },
        }}
        {...props}
        url={url}
        width={width ? parseSize(width) : "100%"}
        height={height ? parseSize(height) : "100%"}
      />
    </div>
  );
};

export default VideoPlayer;
