import ImagePlaceholder from "components/image-placeholder";
import { format, parse } from "date-fns";
import { useRouter } from "next/router";
import React from "react";

import ClippedText from "./clipped-text";
import ImageRenderer from "./rich-text/components/image";
import { SanityRelatedItem } from "./rich-text/types";

const ListItem = ({ _id, title, image, type, slug, postedDate, baseUrl }: SanityRelatedItem & { baseUrl: string }) => {
  const router = useRouter();
  const filterByType = e => {
    e.stopPropagation();
    e.preventDefault();
    router.push(`${baseUrl}?type=${type!.value}`);
  };
  return (
    <a href={`${baseUrl}/${slug}`} className="block group text-left" key={_id}>
      <div key={title} className="flex gap-2">
        <div className="flex shrink-0 min-h-[3.5rem] w-14 min-w-0 relative rounded-lg overflow-hidden border border-gray-200">
          {image ? (
            <ImageRenderer
              value={image}
              className="w-full h-full"
              imageClassName="object-center object-cover crispImage"
              layout="fill"
            />
          ) : (
            <ImagePlaceholder />
          )}
        </div>
        <div className="flex flex-1 flex-col justify-between gap-0.5 self-center">
          <div className="flex gap-x-2 flex-wrap">
            {type && (
              <button
                type="button"
                aria-label={`See more ${type.label} entries`}
                onClick={filterByType}
                className="hover:underline p-0 text-xs font-bold text-primary tracking-wide"
                title={`See more ${type.label} entries`}
              >
                {type.value.toUpperCase()}
              </button>
            )}
            <time dateTime={postedDate} className="text-xs text-gray-400 w-full sm:w-auto">
              {format(parse(postedDate, "yyyy-MM-dd", new Date()), "MMM dd, yyyy")}
            </time>
          </div>
          <p className="text-gray-900 group-hover:text-primary transition-colors block flex-1 font-bold">
            <ClippedText text={title} />
          </p>
        </div>
      </div>
    </a>
  );
};

const RelatedItems = ({ items, baseUrl }: { items: SanityRelatedItem[]; baseUrl: string }) => {
  if (!items?.length) {
    return <p className="text-gray-400 italic text-sm">No items found</p>;
  }

  return (
    <div className="space-y-6">
      {items.map(item => (
        <ListItem {...item} key={item._id} baseUrl={baseUrl} />
      ))}
    </div>
  );
};

export default RelatedItems;
