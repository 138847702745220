import { PortableTextComponents } from "@portabletext/react";

import AlignRenderer from "./align";
import BlockRenderer from "./block";
import Break from "./break";
import BulletList from "./bullet-list";
import ButtonRenderer from "./button";
import FlexWrapperRenderer from "./flex-wrapper";
import GridRenderer from "./grid";
import ImageRenderer from "./image";
import LinkRenderer from "./link";
import NumberList from "./number-list";
import RelatedItemsRenderer from "./related-items";
import ShareIconsRenderer from "./share-icons";
import TextColorRenderer from "./text-color";
import VideoRenderer from "./video";

const portableTextComponents: PortableTextComponents = {
  marks: {
    color: TextColorRenderer,
    link: LinkRenderer,
  },
  types: {
    button: ButtonRenderer,
    image: ImageRenderer,
    video: VideoRenderer,
    break: Break,
    grid: GridRenderer,
    flex: FlexWrapperRenderer,
    shareIcons: ShareIconsRenderer,
    relatedItems: RelatedItemsRenderer,
    align: AlignRenderer,
  },
  block: BlockRenderer,
  list: {
    bullet: BulletList,
    number: NumberList,
  },
};

export default portableTextComponents;
