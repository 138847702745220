import {
  createContext,
  ReactNode,
  useContext,
  useMemo,
} from "react";

import { PageProps } from "./types";

type PageContextType = {
  title?: PageProps["title"];
  description?: PageProps["description"];
};
export const PageContext = createContext<PageContextType>({} as PageContextType);

type PageContextProps = PageProps & {
  children: ReactNode;
};

export const PageContextProvider: React.FC<PageContextProps> = ({ children, title, description }: PageContextProps) => {
  const value = useMemo(
    () => ({
      title,
      description,
    }),
    [title, description]
  );

  return <PageContext.Provider value={value}>{children}</PageContext.Provider>;
};

export const usePage = () => useContext(PageContext);

export const sendAnalyticEvent = (event: string, category: string, label: string) => {
  if (event && typeof window["analytics"] !== "undefined" && process.env.NODE_ENV === "production") {
    window["analytics"].track(event, {
      category,
      label,
    });
  }
};

export default usePage;
