import { ColorOptions } from "./types";

export const backgroundMapping = {
  [ColorOptions.White]: "bg-white",
  [ColorOptions.Primary]: "bg-primary",
  [ColorOptions.Secondary]: "bg-secondary",
  [ColorOptions.Navy]: "bg-navy",
  [ColorOptions.NavyDark]: "bg-navy-dark",
  [ColorOptions.Gray]: "bg-gray-100",
  [ColorOptions.Black]: "bg-black",
};

export const borderColorMapping = {
  [ColorOptions.White]: "border-white",
  [ColorOptions.Primary]: "border-primary",
  [ColorOptions.Secondary]: "border-secondary",
  [ColorOptions.Navy]: "border-navy",
  [ColorOptions.NavyDark]: "border-navy-dark",
  [ColorOptions.Gray]: "border-gray-100",
  [ColorOptions.Black]: "border-black",
};

export const textContrasts = {
  [ColorOptions.Black]: "text-white",
  [ColorOptions.NavyDark]: "text-white",
  [ColorOptions.Navy]: "text-white",
};
