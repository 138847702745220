import { ButtonColorSchemes, ButtonVariants } from "components/button";
import { ShareOptions } from "components/share-icons";
import { ColorOptions } from "components/views/resources/types";

import { PortableTextBlock } from "@portabletext/types";

export interface SanityColorDefinition {
  hex: string;
  alpha: number;
  hsl: {
    h: number;
    s: number;
    l: number;
    a: number;
  };
  hsv: {
    h: number;
    s: number;
    v: number;
    a: number;
  };
  rgb: {
    r: number;
    g: number;
    b: number;
    a: number;
  };
}

export enum TextAlignments {
  Left = "left",
  Right = "right",
  Center = "center",
}
export interface SanityAlign {
  _type: "align";
  alignment: TextAlignments;
  content: PortableTextBlock;
}

export interface SanityImage {
  _type: "image";
  alt: string;
  asset: {
    _type: "reference";
    _ref: string;
  };
}

export interface SanityVideo {
  _key: string;
  _type: "video";
  url: string;
  options?: {
    _type: "videoOptions";
    width?: number;
    height?: number;
  };
}

export interface SanityButton {
  _key: string;
  _type: "button";
  color?: ButtonColorSchemes;
  variant?: ButtonVariants;
  href: string;
  text: string;
  fullWidth: boolean;
  openInNewTab: boolean;
}

export interface SanityLinkMark {
  _key: string;
  _type: "link";
  href: string;
  openInNewTab?: boolean;
}

export interface SanityColorMark {
  _key: string;
  _type: "color";
  color: SanityColorDefinition;
}

export interface SanityRelatedItem {
  _id: string;
  _createdAt: string;
  postedDate: string;
  title: string;
  type?: { label: string; value: string };
  image?: SanityImage;
  slug: string;
}

export interface SanityRelatedItems {
  _key: string;
  _type: "relatedItems";
  type: string;
  itemCount: number;
  items: SanityRelatedItem[];
  baseUrl: string;
}

export interface SanityShareIcons {
  _key: string;
  _type: "shareIcons";
  icons: ShareOptions[];
}

export interface SanityBreak {
  _key: string;
  _type: "break";
  style: "break" | "newLine";
  spacing: number;
}

export interface SanitySideNavSection {
  _key: string;
  _type: "sideNavSection";
  title: string;
  content: (PortableTextBlock | SanityRelatedItems | SanityShareIcons)[];
}

export interface SanitySideNav {
  _key: string;
  _type: "sideNavSection";
  show: boolean;
  side: "left" | "right";
  sections: SanitySideNavSection[];
}

export enum ScreenBreakpoints {
  "base" = "base",
  "2XS" = "2xs",
  "XS" = "xs",
  "SM" = "sm",
  "MD" = "md",
  "LG" = "lg",
  "XL" = "xl",
  "2XL" = "2xl",
}

export interface SanityGridItem {
  _key: string;
  title: string;
  content: PortableTextBlock;
  backgroundColor?: ColorOptions;
  borderRadius?: string;
  borderColor?: ColorOptions;
  paddingX?: number;
  paddingY?: number;
}

export interface SanityGrid {
  _key: string;
  _type: "grid";
  title: string;
  columns?: number;
  autoResize: boolean;
  columnBreakpoints?: { breakpoint: ScreenBreakpoints; columns: number }[];
  items: SanityGridItem[];
}

export enum FlexJustify {
  start = "justify-start",
  end = "justify-end",
  center = "justify-center",
  between = "justify-between",
  around = "justify-around",
  evenly = "justify-evenly",
}

export enum FlexAlign {
  start = "items-start",
  end = "items-end",
  center = "items-center",
  baseline = "items-baseline",
  stretch = "items-stretch",
}

export interface SanityFlexItem extends SanityGridItem {
  grow?: number;
  preventShrink?: boolean;
  maxWidth?: string;
  minWidth?: string;
}

export interface SanityFlex {
  _key: string;
  _type: "flex";
  title: string;
  reverse?: boolean;
  column?: boolean;
  gap?: number;
  justify?: keyof FlexJustify;
  align?: keyof FlexAlign;
  items: SanityFlexItem[];
}
