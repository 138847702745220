import clsx from "clsx";
import { backgroundMapping, borderColorMapping, textContrasts } from "components/views/resources/constants";
import React, { CSSProperties } from "react";

import RichPortableText from "../";
import { SanityGridItem } from "../types";

const GridItemRenderer = ({
  item,
  className,
  style,
}: {
  item: SanityGridItem;
  className?: string;
  style?: CSSProperties;
}) => {
  return (
    <div
      key={item._key}
      style={style}
      className={clsx(
        className,
        item.backgroundColor && backgroundMapping[item.backgroundColor],
        item.backgroundColor && textContrasts[item.backgroundColor],
        item.paddingY && `py-${item.paddingY}`,
        item.paddingX && `px-${item.paddingX}`,
        item.borderRadius && `rounded${item.borderRadius === "base" ? "" : `-${item.borderRadius}`}`,
        item.borderColor && `border-2 ${borderColorMapping[item.borderColor]}`
      )}
    >
      <RichPortableText value={item.content} />
    </div>
  );
};

export default GridItemRenderer;
