import { FC } from "react";

import clsx from "clsx";

import styles from "./index.module.css";

type HeaderTags = "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
type HeaderWeight = "regular" | "bold";
export interface HeadingProps {
  className?: string;
  tag?: HeaderTags;
  size?: HeaderTags;
  fontWeight?: HeaderWeight;
}

const Heading: FC<HeadingProps> = props => {
  const { tag: Tag = "h6", size = Tag, fontWeight = "bold" } = props;

  return <Tag className={clsx(styles[fontWeight], styles[size], props.className)}>{props.children}</Tag>;
};

export default Heading;
