import React, {
  Fragment,
  useCallback,
} from "react";

import clsx from "clsx";
import Button, { ButtonColorSchemes } from "components/button";
import Link from "next/link";
import {
  NextRouter,
  useRouter,
} from "next/router";

import {
  Menu,
  Transition,
} from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/outline";

import {
  NavigationItem,
  NavItemTypes,
} from "../navigation";
import { sendAnalyticEvent } from "../page/use-page";
import styles from "./index.module.css";

export const isItemCurrent = (item: NavigationItem, router: NextRouter, isDefault?: boolean) => {
  if (isDefault) {
    return router.pathname === item.href;
  }

  return router.pathname.includes(item.href);
};

const NavItem = ({ item, className }: { item: NavigationItem; className?: string }) => {
  const { name, href, items, type, variant, analyticEvent, color = ButtonColorSchemes.Primary } = item;
  const router = useRouter();
  const isCurrent = isItemCurrent(item, router);
  const handleClick = useCallback(
    (aEvent = analyticEvent) => {
      if (aEvent) {
        sendAnalyticEvent(aEvent.event, aEvent.category, aEvent.label);
      }
    },
    [analyticEvent]
  );

  if (items?.length) {
    return (
      <Menu as="div" className="relative inline-block">
        <Menu.Button
          className={clsx(
            className,
            "flex items-center h-full",
            styles.desktopNavItem,
            styles.desktopNavLink,
            !isCurrent && styles.inactiveNavLink
          )}
        >
          <span>{name}</span>
          <ChevronDownIcon className="h-4 w-4 ml-1" />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 w-56 py-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            {items
              .filter(i => !i.footerOnly)
              .map(i => (
                <Menu.Item key={i.name}>
                  <a
                    href={i.href}
                    className={clsx(
                      className,
                      "!block !px-4",
                      styles.desktopNavLink,
                      !isItemCurrent(i, router, i.href === href) && styles.inactiveNavLink
                    )}
                    onClick={() => handleClick(i.analyticEvent)}
                  >
                    {i.name}
                  </a>
                </Menu.Item>
              ))}
          </Menu.Items>
        </Transition>
      </Menu>
    );
  }

  if (type === NavItemTypes.Button) {
    return (
      <Button
        className={clsx(
          className,
          styles.desktopNavItem,
          styles.navButton,
          !href.startsWith("/") && styles.desktopNavLink
        )}
        href={href}
        onClick={() => handleClick()}
        variant={variant}
        color={color}
      >
        {name}
      </Button>
    );
  }

  return (
    <Link key={name} href={href}>
      <a
        className={clsx(className, styles.desktopNavItem, styles.desktopNavLink, !isCurrent && styles.inactiveNavLink)}
        aria-current={isCurrent ? "page" : undefined}
        onClick={() => handleClick()}
      >
        {name}
      </a>
    </Link>
  );
};

export default NavItem;
