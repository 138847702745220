import clsx from "clsx";
import { SanityGrid, ScreenBreakpoints } from "components/rich-text/types";
import React, { ReactNode, useMemo } from "react";

const defaultResponsiveGrid = {
  1: "grid-cols-1",
  2: "grid-cols-1 md:grid-cols-2",
  3: "grid-cols-1 sm:grid-cols-2 lg:grid-cols-3",
  4: "grid-cols-1 xs:grid-cols-2 md:grid-cols-3 xl:grid-cols-4",
  5: "grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5",
  6: "grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-6",
};

export const screensOrder = [
  ScreenBreakpoints["base"],
  ScreenBreakpoints["2XS"],
  ScreenBreakpoints.XS,
  ScreenBreakpoints.SM,
  ScreenBreakpoints.MD,
  ScreenBreakpoints.LG,
  ScreenBreakpoints.XL,
  ScreenBreakpoints["2XL"],
];

type GridProps = {
  columns?: number | string;
  children: ReactNode;
  autoResize?: boolean;
  columnBreakpoints?: SanityGrid["columnBreakpoints"];
};
const Grid = ({ columns, children, autoResize = true, columnBreakpoints }: GridProps) => {
  const gridClasses = useMemo(() => {
    if (!autoResize) {
      return (columnBreakpoints || []).map(item => {
        const prefix = item.breakpoint !== ScreenBreakpoints["base"] ? `${item.breakpoint}:` : "";
        return `${prefix}grid-cols-${item.columns}`;
      });
    }

    return defaultResponsiveGrid[columns as number];
  }, [autoResize, columnBreakpoints, columns]);

  if (!React.Children.count(children)) {
    return <p className="p-20 md:p-36 text-center text-gray-400">No items</p>;
  }

  return <div className={clsx(gridClasses, "grid gap-4 md:gap-6")}>{children}</div>;
};

export default Grid;
