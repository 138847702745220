import React from "react";

import { PortableTextMarkComponent } from "@portabletext/react";

import { SanityColorMark } from "../types";

const TextColorRenderer: PortableTextMarkComponent<SanityColorMark> = ({ children, value }) => {
  const style = value?.color ? { color: value?.color.hex } : undefined;

  return <span style={style}>{children}</span>;
};

export default TextColorRenderer;
