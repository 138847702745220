import _isNil from "lodash/isNil";
import React, { CSSProperties, useMemo } from "react";

import { SanityFlexItem } from "../types";
import GridItemRenderer from "./grid-item";

const FlexItemRenderer = ({ item }: { item: SanityFlexItem }) => {
  const style = useMemo(() => {
    const result: CSSProperties = {};

    if (!_isNil(item.grow)) {
      result.flexGrow = item.grow;
    }

    if (item.preventShrink) {
      result.flexShrink = 0;
    }

    if (!_isNil(item.maxWidth)) {
      result.maxWidth = item.maxWidth;
    }

    if (!_isNil(item.minWidth)) {
      result.minWidth = item.minWidth;
    }

    return result;
  }, [item.grow, item.maxWidth, item.minWidth, item.preventShrink]);

  return <GridItemRenderer item={item} style={style} />;
};

export default FlexItemRenderer;
