import ShareIcons from "components/share-icons";
import { useRouter } from "next/router";
import React from "react";

import { SanityShareIcons } from "../types";

const ShareIconsRenderer = ({ value }: { value: SanityShareIcons }) => {
  const router = useRouter();
  return <ShareIcons {...value} url={`${process.env.SITE_URL}${router.asPath}`} />;
};

export default ShareIconsRenderer;
