const server = typeof window === "undefined";

module.exports = {
  projectId: process.env.NEXT_PUBLIC_SANITY_API_PROJECT_ID,
  dataset: process.env.NEXT_PUBLIC_SANITY_API_DATASET,
  // useCdn == true gives fast, cheap responses using a globally distributed cache.
  // Set this to false if your application require the freshest possible
  // data always (potentially slightly slower and a bit more expensive).
  useCdn: !server,
  apiVersion: "2022-11-14",
};
