import clsx from "clsx";
import React from "react";

import { SanityBreak } from "../types";

const Break = ({ value }: { value: SanityBreak }) => {
  const spacingClass = `p-${value.spacing || 0.5}`;

  if (value.style === "newLine") {
    return <div className={spacingClass} />;
  }

  return <hr className={clsx(spacingClass, "border-black border-opacity-30")} />;
};

export default Break;
