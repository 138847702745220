import { FC } from "react";

import { PortableText, PortableTextProps } from "@portabletext/react";

import portableTextComponents from "./components";

const RichPortableText: FC<PortableTextProps> = ({ ...props }: PortableTextProps) => (
  <PortableText {...props} components={portableTextComponents} />
);

export default RichPortableText;
