import React from "react";

import RichPortableText from "../";
import { SanityAlign, TextAlignments } from "../types";

const alignMapping = {
  [TextAlignments.Left]: "text-left",
  [TextAlignments.Right]: "text-right",
  [TextAlignments.Center]: "text-center",
};

const AlignRenderer = ({ value }: { value: SanityAlign }) => (
  <div className={alignMapping[value.alignment]}>
    <RichPortableText value={value.content} />
  </div>
);

export default AlignRenderer;
