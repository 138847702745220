import Grid from "components/grid";
import React from "react";

import { SanityGrid } from "../types";
import GridItemRenderer from "./grid-item";

const GridRenderer = ({ value }: { value: SanityGrid }) => {
  return (
    <Grid columns={value.columns} columnBreakpoints={value.columnBreakpoints} autoResize={value.autoResize}>
      {value.items.map(item => (
        <GridItemRenderer key={item._key} item={item} />
      ))}
    </Grid>
  );
};

export default GridRenderer;
