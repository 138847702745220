import { NextSeo } from "next-seo";
import { useRouter } from "next/router";
import { FC } from "react";

import { AppFooter } from "../footer";
import { AppHeader } from "../header";
import { PageProps } from "./types";
import { PageContextProvider } from "./use-page";

const Page: FC<PageProps> = ({ children, title, description, navItems, imageUrl, footer }: PageProps) => {
  const router = useRouter();
  const image = imageUrl || `${process.env.SITE_URL}/enerflo-dark.png`;
  return (
    <>
      <NextSeo
        title={title}
        description={description}
        canonical={`${process.env.SITE_URL}${router.asPath}`}
        additionalMetaTags={[
          { property: "og:url", content: `${process.env.SITE_URL}${router.asPath}` },
          { property: "og:image", content: image },
        ]}
      />

      <AppHeader navItems={navItems} />
      <main>
        <PageContextProvider title={title} description={description}>
          {children}
        </PageContextProvider>
      </main>
      <AppFooter {...footer} />
    </>
  );
};

export default Page;
