import clsx from "clsx";
import Button from "components/button";
import React from "react";

import { PortableTextComponent } from "@portabletext/react";

import { SanityButton } from "../types";

const ButtonRenderer: PortableTextComponent<SanityButton> = ({ value }) => (
  <Button
    color={value.color}
    variant={value.variant}
    className={clsx(value.fullWidth && "w-full text-center")}
    href={value.href}
    target={value.openInNewTab ? "_blank" : undefined}
    rel={value.openInNewTab ? "noopener noreferrer" : undefined}
  >
    {value.text}
  </Button>
);

export default ButtonRenderer;
