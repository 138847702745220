type ClippedTextProps = { text: string; clipAt?: number };

const ClippedText = ({ text, clipAt = 70 }: ClippedTextProps) => {
  const clippedValue = (text || "").substring(0, clipAt).trim();
  const isClipped = clippedValue.length < text?.length;

  return (
    <>
      {clippedValue}
      {isClipped && "..."}
    </>
  );
};

export default ClippedText;
