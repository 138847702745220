import clsx from "clsx";
import React from "react";

import { FlexAlign, FlexJustify, SanityFlex } from "../types";
import FlexItemRenderer from "./flex-item";

const getFlexDirection = value => {
  if (value.reverse) {
    return value.column ? "flex-col-reverse" : "flex-row-reverse";
  }
};

const FlexWrapperRenderer = ({ value }: { value: SanityFlex }) => {
  return (
    <div
      className={clsx(
        "flex flex-col flex-wrap",
        !value.column && "sm:flex-row",
        getFlexDirection(value),
        value.gap && `gap-${value.gap}`,
        value.justify && FlexJustify[value.justify],
        value.align && FlexAlign[value.align]
      )}
    >
      {value.items.map(item => (
        <FlexItemRenderer key={item._key} item={item} />
      ))}
    </div>
  );
};

export default FlexWrapperRenderer;
