import React from "react";

import { PortableTextMarkComponent } from "@portabletext/react";

import { SanityLinkMark } from "../types";

const LinkRenderer: PortableTextMarkComponent<SanityLinkMark> = ({ children, value }) => {
  if (!value?.href) return <>{children}</>;

  return (
    <a
      href={value?.href}
      className="text-primary underline hover:opacity-80 transition-opacity"
      target={value?.openInNewTab ? "_blank" : undefined}
      rel="noopener noreferrer"
    >
      {children}
    </a>
  );
};

export default LinkRenderer;
