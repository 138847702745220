import { Footer } from "components/layout/footer";
import { NavbarVariants, NavigationItem } from "components/layout/navigation";
import { SanityImage, SanitySideNav } from "components/rich-text/types";

import { PortableTextBlock } from "@portabletext/types";

export enum ColorOptions {
  White = "white",
  Primary = "primary",
  Secondary = "secondary",
  Navy = "navy",
  NavyDark = "navy-dark",
  Gray = "gray",
  Black = "black",
}

export type ResourceItem = {
  _id: string;
  _createdAt: string;
  seoTitle: string;
  seoDescription?: string;
  postedDate: string;
  type: { label: string; value: string };
  topics: { label: string; value: string }[];
  image?: SanityImage;
  authors: { firstName: string; lastName: string }[];
  sideNav?: SanitySideNav;
  slug: string;
  title: string;
  navbar: {
    variant: NavbarVariants;
    items?: NavigationItem[];
  };
  content: { _key: string; title: string; content: PortableTextBlock[]; backgroundColor?: ColorOptions }[];
  footer?: Footer;
};
